.CalendarDay__selected_span {
    background: #9cc0e3;
    color: white;
    border: none;
}

.CalendarDay__selected_span:hover {
    background: #275b8b;
    color: white;
    border: none;
}

.CalendarDay__selected {
    background: #275b8b;
    color: white;
    border-color: #275b8b;
}

.CalendarDay__selected:hover {
    background: #275b8b;
    color: white;
    border-color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #9cc0e3;
    border-color: white;
    color: white;
}

.DateInput {
    background-color: #f2f3f4;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
}

.DateInput_input__focused {
    background-color: #f2f3f4;
    color: black;
    border: none;
}

.DateRangePickerInput input {
    background-color: #f2f3f4;
    border: none;
    color: black;
    padding: 11px 7px;
    text-align: center;
}

.SingleDatePickerInput input {
    background-color: #f2f3f4;
    border: none;
    color: black;
    padding: 11px 7px;
}

.DateInput_input ::placeholder {
    color: black;
}

.DateInput_fang {
    display: none;
}

.DateRangePickerInput {
    background-color: #f2f3f4;
    marginLeft: 4px;
    marginRight: 4px;
    border-radius: 4px;
    width: 100%;
}

.DateRangePickerInput_calendarIcon_svg {
    fill: black;
}

.DateRangePickerInput_calendarIcon {
    padding-left: 8px;
    padding-top: 7px;
}

.SingleDatePicker {
    width: 100%;
}

.SingleDatePickerInput {
    width: 100%;
}

.SingleDatePickerInput .DateInput {
    border: 1px solid #959ba1;
    border-radius: 4px;
    width: 100%;
}

.SingleDatePickerInput .DateInput input {
    text-align: center;
    margin: 0px;
    font-weight: 400;
    font-size: 17px;
    caret-color: transparent;
}

.SingleDatePickerInput .DateInput input::placeholder {
    text-align: center;
    color: black;
}
